import { NextPage } from "next";
import styles from './ProgressBar.module.scss'
import { IHotelAttributes } from "src/models/hotels";
import Link from "next/link";

interface Props {
   hotel: IHotelAttributes;
}

const ProgressBar: NextPage<Props> = ({ hotel }) => {

   return (
      <div className={styles.block}>
         <div className={styles.top}>
            <div className={styles.name}><Link href={`/hotel/edit/${hotel.id}`}>{hotel.name ? hotel.name : 'Имя не указано'}</Link></div>
            <div className={styles.address}>Россия{hotel.region && `, ${hotel.region},`} {hotel.city && `${hotel.city},`} {hotel.street && `${hotel.street},`} {hotel.house && `${hotel.house},`}</div>
         </div>
         <div className={styles.bottom}>
            <div className={styles.bottom_title}>Статус регистрации:</div>
            <ul>
               <li>
                  <div className={styles.circle}>
                     <div className={styles.circle_content}></div>
                  </div>
                  <div className={styles.li_text}>Вы регистрируйте объект</div>
               </li>
               <li>
                  <div className={hotel.status === 1 ? `${styles.circle} ${styles.one}` : styles.circle}>
                     <div className={styles.circle_content}></div>
                  </div>
                  <div className={styles.li_text}>Shelterz.io проверяет информацию</div>
               </li>
               <li>
                  <div className={hotel.status === 2 ? `${styles.circle} ${styles.two}` : styles.circle}>
                     <div className={styles.circle_content}></div>
                  </div>
                  <div className={styles.li_text}>Вы получаете доступ к экстранету</div>
               </li>
            </ul>
            <div className={hotel.status === 1 ? `${styles.info} ${styles.one}` : hotel.status === 2 ? `${styles.info} ${styles.two}` : styles.info}>
               {
                  hotel.status === 1
                     ? 'Регистрацию вашего объекта проверяют. Когда все будет готово, мы отправим вам электронное письмо.'
                     : 'Ваш объект успешно размещен!'
               }
            </div>
         </div>
      </div>
   )
}

export default ProgressBar