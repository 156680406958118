import * as React from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import { withAuth } from '../middlewares/routes/withAuth';
import { MainPageHeader } from '../app/components/mainPageHeader/mainPageHeader';
import { getHotelApi } from '../app/api/hotellist-get';
import { COOKIE_ACCESS_TOKEN_KEY } from '../config';
import { IHotelAttributes } from '../models/hotels';
import Typography from 'antd/lib/typography';
import { Content } from 'antd/lib/layout/layout';
import { useSelector } from 'react-redux';
import { IStore } from '../app/store/store';
import { getHotelPhotosApi } from 'src/app/api/hotel-photos-get';
import { IHotelsPhotoAttributes } from 'src/models/hotels-photos';
import ManePageObjects from 'src/app/components/manePageObjects/ManePageObjects';

export interface IPageWithUser {
  // photos: IHotelsPhotoAttributes[]
  hotelsList: IHotelAttributes[];
}

const Home: NextPage<IPageWithUser> = ({ hotelsList }) => {
  const user = useSelector((store: IStore) => store.user);

  if (!user) {
    return (
      <div>
        <MainPageHeader />
        <Content style={{ maxWidth: '1140px', background: '#fff', margin: '20px auto', padding: '20px' }}>
          <Typography.Title level={4}>промо-текст</Typography.Title>
        </Content>
      </div>
    );
  }
  return (
    <div>
      <MainPageHeader />
      <Content>
        {hotelsList.length === 0 ? (
          <Typography.Title level={4} style={{ textAlign: 'center' }}>
            У вас пока нет объектов
          </Typography.Title>
        ) : (
          <ManePageObjects hotelsList={hotelsList} />
        )}
      </Content>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = withAuth(async (context) => {
  const token = context.req.cookies[COOKIE_ACCESS_TOKEN_KEY];
  const hotelsList = token ? await getHotelApi(token) : [];

  const hotelsWithPhotos = await Promise.all(
    hotelsList.map(async (hotel) => {
      const photos = await getHotelPhotosApi(token ?? '', hotel.id);

      return {
        ...hotel,
        firstPhotoPath: (photos ?? [])?.length > 0 ? (photos ?? [])[0]?.path : null,
      };
    }),
  );

  return {
    props: { hotelsList: hotelsWithPhotos },
  };
});

export default Home;
