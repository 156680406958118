import Button from 'antd/lib/button';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styles from './mainPageHeader.module.scss'
import { IStore } from "../../store/store";
import Card from "antd/lib/card/Card";
import Row from "antd/lib/grid/row";
import Col from "antd/lib/grid/col";
import { ROUTES } from "../../../routes/routes";
import Link from 'next/link';
import { useRouter } from 'next/router';

export const MainPageHeader: React.FunctionComponent = () => {
  const router = useRouter()

  const user = useSelector((store: IStore) => {
    return store.user;
  })

  return <div className={user ? `${styles.container} ${styles.user}` : styles.container}>
    <div className={styles.content}>
      {
        user
          ?
          <div>
            <div className={styles.title}>Мои объекты размещения</div>
            <div className={styles.top_content}>
              <button onClick={() => router.push(ROUTES.HOTEL_NEW)}>Добавить другой объект размещения</button>
            </div>
          </div>
          :
          <Row gutter={[16, 8]} align="middle">
            <Col xs={24} md={12} lg={16}>
              <Title level={2} style={{ color: '#fff' }}>Зарегистрируйте ваш объект на Shelterz.ru</Title>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Card>
                <Title level={4}>Зарегистрировать новый объект размещения</Title>
                <Paragraph>
                  <ul>
                    <li>Более 6,4 миллиона зарегистрированных вариантов жилья для отдыха</li>
                    <li>Более 1 миллиарда заездов по бронированиям жилья для отдыха</li>
                    <li>Более 40% партнеров, которые впервые регистрируют жилье для отдыха, получают первое бронирование в течение недели</li>
                  </ul>
                </Paragraph>
                <Link href={ROUTES.REGISTER}>
                  <Button block>Создать аккаунт</Button>
                </Link>
              </Card>
            </Col>
          </Row>
      }
    </div>
  </div>;
};
