import { NextPage } from 'next';
import styles from './Card.module.scss';
import { IHotelAttributes } from 'src/models/hotels';
import ProgressBar from '../progress_bar/ProgressBar';
import ContentNoReg from '../content_noreg/ContentNoReg';

interface Props {
  hotel: IHotelAttributes;
}

const Card: NextPage<Props> = ({ hotel }) => {
  console.log(hotel);
  return (
    <div className={styles.block}>
      <div className={styles.left}></div>
      <div className={styles.img}>
        <img src={`/images.shelterz.io/public/hotel_photos/${hotel.firstPhotoPath}`} alt="" />
      </div>
      <div className={styles.right}>
        {hotel.status ? <ProgressBar hotel={hotel} /> : <ContentNoReg hotel={hotel} />}
      </div>
    </div>
  );
};

export default Card;
