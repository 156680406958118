import { NextPage } from 'next';
import styles from './ContentNoReg.module.scss';
import { useRouter } from 'next/router';
import { IHotelAttributes } from 'src/models/hotels';
import { useEffect, useState } from 'react';
import { ROUTES } from 'src/routes/routes';

interface Props {
  hotel: IHotelAttributes;
}

const ContentNoReg: NextPage<Props> = ({ hotel }) => {
  const router = useRouter();

  return (
    <div className={styles.block}>
      <div className={styles.title}>Новый объект размещения</div>
      <div className={styles.progress}></div>
      <div className={styles.progress_text}>Ваша страница заполнена на 10%</div>
      <button
        onClick={() => {
          const route = ROUTES.HOTEL_EDIT_BASICS.replace('[id]', hotel.id.toString());
          router.push(route);
        }}
      >
        Продолжить регистрацию
      </button>
    </div>
  );
  // const [progress, setProgress] = useState(0)
  // useEffect(() => {
  //    switch (hotel.progress) {
  //       case 0: setProgress(0)
  //       case 1: setProgress(25)
  //       case 2: setProgress(50)
  //       case 3: setProgress(75)
  //    }
  // }, [])

  // return (
  //    <div className={styles.block}>
  //       <div className={styles.title}>Новый объект размещения</div>
  //       <div className={styles.progress}>
  //          <div className={styles.progress_content} style={{ width: `${progress}%` }}></div>
  //       </div>
  //       <div className={styles.progress_text}>Ваша страница заполнена на {progress}%</div>
  //       <button onClick={() => router.push(`/`)}>Продолжить регистрацию</button>
  //    </div>
  // )
};

export default ContentNoReg;
