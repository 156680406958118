import { Typography } from 'antd';
import { NextPage } from 'next';
import { IHotelAttributes } from 'src/models/hotels';
import styles from './ManePageObjects.module.scss';
import Card from './ui/card/Card';
import { useEffect } from 'react';

interface Props {
  hotelsList: IHotelAttributes[];
}

const ManePageObjects: NextPage<Props> = ({ hotelsList }) => {
  const unreadyHotels = hotelsList
    .filter((hotel) => hotel.status === 0)
    .sort((a, b) => {
      const aDate = new Date(a.modify_date).valueOf();
      const bDate = new Date(b.modify_date).valueOf();

      return aDate - bDate;
    });

  return (
    <div className={styles.block}>
      <div className={styles.title}>Объекты в процессе регистрации</div>
      <div className={styles.cards}>
        {unreadyHotels.length ? (
          unreadyHotels.map((hotel) => <Card key={hotel.id} hotel={hotel} />)
        ) : (
          <div className={styles.none}>Отели отсутствуют</div>
        )}
      </div>
      <div className={styles.title}>Объекты на модерации</div>
      <div className={styles.cards}>
        {hotelsList.filter((h) => h.status == 1).length ? (
          hotelsList.filter((h) => h.status == 1).map((hotel) => <Card key={hotel.id} hotel={hotel} />)
        ) : (
          <div className={styles.none}>Отели отсутствуют</div>
        )}
      </div>
      <div className={styles.title}>Активные объекты</div>
      <div className={styles.cards}>
        {hotelsList.filter((h) => h.status == 2).length ? (
          hotelsList.filter((h) => h.status == 2).map((hotel) => <Card key={hotel.id} hotel={hotel} />)
        ) : (
          <div className={styles.none}>Отели отсутствуют</div>
        )}
      </div>
    </div>
  );
};

export default ManePageObjects;
